"use strict";


const OPENMAPTILES_URL = "https://api-l.cofractal.com/v0/maps/vt/overture";
const ATTRIBUTION_TEXT =
  '<a href="https://github.com/osm-americana/openstreetmap-americana" target="_blank">Style OSM Americana, (c) OpenStreetMap contributors</a>';


const SHIELD_TEXT_BBOX_COLOR = null;
const SHIELD_TEXT_HALO_COLOR_OVERRIDE = null;

export default {
  OPENMAPTILES_URL,
  ATTRIBUTION_TEXT,
  SHIELD_TEXT_BBOX_COLOR,
  SHIELD_TEXT_HALO_COLOR_OVERRIDE,
};
